import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    height: theme.spacing(6),
    background: theme.palette.common.white,
    padding: `0 ${theme.spacing(2)}`,
    zIndex: 2,
  },
  logo: {
    height: '100%',
    padding: '10px 0',
  },
  logoImg: {
    height: '100%',
    objectFit: 'contain',
    verticalAlign: 'middle',
    cursor: 'pointer',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(3),
    color: theme.palette.secondary.main,
  },
  buttonAction: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 'auto',
  },

  iconTopBar: {},
}));
