import { Box, CircularProgress } from '@mui/material';
import Cookies from 'js-cookie';
import _ from 'lodash';
import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { useIsRequestPending } from 'src/hooks';
import { useAppSelector } from 'src/redux_store';
import { PATH } from 'src/routes/path';
import imageBackground from '../../assets/images/background.jpg';
import { useStyles } from './style';
import i18n from 'src/i18n';

const AuthLayout = ({ children }: PropsWithChildren) => {
  const classes = useStyles();
  const isLoadingGetMe = useIsRequestPending('myAccount', 'getMe');
  const { me } = useAppSelector((state) => state.myAccountSlice);
  const token = Cookies.get('USER_SESSION');

  document.title = i18n.t("label.danapodAccount")


  if (token && !_.isEmpty(me)) {
    return <Navigate to={PATH.home} replace />;
  }

  if (isLoadingGetMe) {
    return (
      <Box className={classes.center}>
        <CircularProgress color="error" />
      </Box>
    );
  }

  return (
    <Box
      className={classes.root}
      sx={{
        backgroundImage: `url(${imageBackground})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      {children}
    </Box>
  );
};

export default AuthLayout;
