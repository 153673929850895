import { Delete } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, DialogTitle } from '@mui/material';
import { ReactNode } from 'react';

import { useAppDispatch } from 'src/redux_store';
import DialogWrapper from '../dialog_wrapper';
import { useStyles } from './styles';

import { useTranslation } from 'react-i18next';
import { useIsRequestPending } from 'src/hooks';
import { closeModal } from 'src/redux_store/common/modal/modal_slice';

interface IConfirmationDialog {
  modalId: string;
  describe: ReactNode;
  cancelLabel?: string;
  okLabel?: string;
  icon?: ReactNode;
  sliceName: string;
  functionName: string;
  children?: ReactNode;
  callback: () => any;
  prevClose?: () => void;
}

function ConfirmationDialog(props: IConfirmationDialog) {
  const { t } = useTranslation();
  const {
    modalId,
    describe,
    icon,
    sliceName,
    functionName,
    cancelLabel = t('button.cancel'),
    okLabel = t('button.continue'),
    children,
    callback,
    prevClose,
  } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const isLoading = useIsRequestPending(sliceName, functionName);

  const handleClose = () => {
    dispatch(closeModal({ modalId }));
    if (prevClose) {
      prevClose();
    }
  };

  return (
    <DialogWrapper modalId={modalId} prevClose={prevClose}>
      <Box className={classes.dialog}>
        <Box className={classes.icon}>
          {icon ? icon : <Delete fontSize="large" color="error" />}
        </Box>
        <DialogTitle className={classes.dialogTitle}>{describe}</DialogTitle>
        {children}
        <Box className={classes.actionButton}>
          <Button color="primary" onClick={handleClose}>
            {cancelLabel}
          </Button>
          <LoadingButton variant="contained" onClick={callback} loading={isLoading}>
            {okLabel}
          </LoadingButton>
        </Box>
      </Box>
    </DialogWrapper>
  );
}

export default ConfirmationDialog;
