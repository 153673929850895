import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    backgroundSize: 'cover',
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
  },
});
